import React from 'react';
import { SocialIcon } from 'react-social-icons';
import Typed from 'react-typed';

function Home() {
    return (
        <div classname="Home">

            <div className="intro">
                <h1>
                <Typed
                    strings={[
                    "Hi, my name is Cam!"
                    ]}
                    typeSpeed={40}
                    backSpeed={40}
                    showCursor={false}
                />
                </h1>
                <h2>
                <Typed
                    strings={[
                    "This is my personal website, where you can find past coding projects and a few other fun things."
                    ]}
                    startDelay={1500}
                    typeSpeed={20}
                    showCursor={false}
                />
                </h2>

            </div>

            If you would like to reach out, don't hesitiate! <br /> <br />
            <div classname="socialBar">
                <SocialIcon className="social" url="https://www.instagram.com/cammayhew/" /> &nbsp;&nbsp;&nbsp;&nbsp; <SocialIcon className="social" url="https://linkedin.com/in/cameron-mayhew" />
            </div>
        </div>
    );
}

export default Home;