import React from 'react';

function Music() {
    return (
      <div classname="Music">
        <h1>If you didn't know, I love musicals.</h1>
        <h3> Click here to see my resume </h3>
        <h3> Click here to see my showreel! </h3>
      </div>
    );
}

export default Music;